




















































































































































































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      items: [],
      totalItem: null,
      users: [],
      currentUser: null,
      currentDate: "",
      startDate: "",
      endDate: "",
      currentStatus: "dtl",
      loading: false,
      headers: [
        { text: "Nhân viên", value: "staff_name" },
        { text: "Chỉ tiêu vận chuyển", value: "target_vc" },
        { text: "Doanh số vận chuyển kí gửi (%)", value: "fee_transport_k" },
        { text: "Doanh số vận chuyển order (%)", value: "fee_transport_o" },
        {
          text: "Tổng doanh số vận chuyển (%)",
          value: "fee_transport_percentage",
        },
        { text: "Chỉ tiêu thanh toán", value: "target_tt" },
        { text: "Doanh số thanh toán", value: "profit" },
        { text: "% hoa hồng vận chuyển", value: "commission_vc" },
        { text: "% hoa hồng thanh toán", value: "commission_tt" },
        { text: "Lương vận chuyển NV", value: "tpkd_fee_transport" },
        { text: "Lương thanh toán NV", value: "tpkd_profit" },
      ],
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "custom",
          text: "Tùy chỉnh",
        },
        // {
        //   value: "year",
        //   text: "Năm",
        // },
      ],
      timeMode: "month",
      stages: [
        {
          value: "stage1",
          text: "Ngày 1-10",
        },
        {
          value: "stage2",
          text: "Ngày 11-20",
        },
        {
          value: "stage3",
          text: "Ngày 21-cuối tháng",
        },
        {
          value: "",
          text: "Tất cả",
        },
      ],
      stage: "",
      userInfo: null,
      availableStatuses: [
        { value: "dtl", text: "Đã thanh lý" },
        { value: "ctl", text: "Chưa thanh lý" },
        { value: "hdv", text: "Hàng đã về" },
      ],
      userMonths: [],
      kds: [],
      permittedKds: [],
      targetDialog: false,
      targetForm: false,
    };
  },
  watch: {
    currentUser() {
      this.initialize();
    },
    currentDate() {
      this.initialize();
    },
    startDate() {
      this.initialize();
    },
    endDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
    currentStatus() {
      this.initialize();
    },
    stage() {
      this.initialize();
    },
  },
  computed: {
    month() {
      return moment(this.currentDate).startOf("month").unix();
    },
    startMonth() {
      return moment(this.startDate).startOf("month").unix();
    },
    endMonth() {
      return moment(this.endDate).endOf("month").unix();
    },
    startTime() {
      let m = moment(this.currentDate).startOf(this.timeMode);
      if (this.timeMode === "custom") {
        m = moment(this.startDate);
      } else if (this.timeMode === "month") {
        if (this.stage) {
          switch (this.stage) {
            case "stage1":
              break;
            case "stage2":
              m = m.add(10, "days");
              break;
            case "stage3":
              m = m.add(20, "days");
              break;
          }
        }
      }
      return m.startOf("day").unix();
    },
    endTime() {
      let m = moment(this.currentDate).endOf(this.timeMode);
      if (this.timeMode === "custom") {
        m = moment(this.endDate);
      } else if (this.timeMode === "month") {
        if (this.stage) {
          m = m.startOf("month");
          switch (this.stage) {
            case "stage1":
              m = m.add(9, "days");
              break;
            case "stage2":
              m = m.add(19, "days");
              break;
            case "stage3":
              m = m.endOf("month");
              break;
          }
        }
      }
      return m.endOf("day").unix();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentUser || !this.currentDate) {
        return;
      }
      this.loading = true;
      const self = this;
      if (this.timeMode === "month") {
        this.userMonths = await apiClient.getUserMonths(this.month);
      } else if (this.timeMode === "custom") {
        this.userMonths = await apiClient.getUserMonthsInRange(this.startMonth, this.endMonth);
      }
      this.permittedKds = this.kds;
      if (userManager.checkRole(["tpkd"], true) && !userManager.checkRole(["gdkd"], true)) {
        if (this.timeMode === "month") {
          this.permittedKds = await apiClient.getStaffByUserAtMonth(userManager.getUserInfo().sub, this.month);
        } else if (this.timeMode === "custom") {
          this.permittedKds = await apiClient.getStaffByUserAtMonthInRange(
            userManager.getUserInfo().sub,
            this.startMonth,
            this.endMonth
          );
        }
      }
      for (const kd of this.permittedKds) {
        const userMonth = this.userMonths.find((um) => um.user_id === kd.id);
        kd.target_vc = userMonth ? userMonth.target_vc : 0;
        kd.target_tt = userMonth ? userMonth.target_tt : 0;
      }
      {
        let users;
        if (this.timeMode === "month") {
          users = await apiClient.getStaffByUserAtMonth(this.currentUser.id, this.month);
        } else if (this.timeMode === "custom") {
          users = await apiClient.getStaffByUserAtMonthInRange(this.currentUser.id, this.startMonth, this.endMonth);
        }
        const options: any = {
          extra: {
            statistics: true,
            truck_statistics: true,
            user_tpkd_report: true,
          },
          filters: [],
          report: true,
        };
        options.auth_override = {
          id: 0,
          role: "kd",
          codes: [],
          ids: users.map((u) => u.id),
        };
        switch (this.currentStatus) {
          case "dtl":
            options.extra.liquidation_slip_approve_time = {
              from: this.startTime,
              to: this.endTime,
            };
            break;
          case "ctl":
            options.extra.truck_vn_time = {
              from: this.startTime,
              to: this.endTime,
            };
            options.extra.liquidation_slip_approved = false;
            break;
          case "hdv":
            options.extra.truck_vn_time = {
              from: this.startTime,
              to: this.endTime,
            };
            break;
        }
        const { items } = await apiClient.packageGetAll(options);
        console.log(items);
        this.items = [];
        for (const user of users) {
          const userItems = items
            .filter((p) => p.sale_user_id === user.id)
            .map((item) => {
              if (item.truck) {
                item.salary_dsvc =
                  ((item.truck.type.startsWith("db") && 0.08) || (item.truck.type.startsWith("nx") && 0.02) || 0.04) *
                  item.fee_transport;
              }
              item.salary_dvtt = item.profit * 0.2;
              if (item.truck_total_volume) {
                item.cost = (item.volume / item.truck_total_volume) * (item.truck?.export_fee_total || 0);
              } else {
                item.cost = 0;
              }
              item.package_profit = item.fee_transport + item.fee_trust + item.profit - item.cost;
              return item;
            });
          const userMonth = this.userMonths.find((um) => um.user_id === user.id);
          const totalItem = userItems.reduce(
            (totalItem, item) => {
              totalItem.fee_transport += item.fee_transport;
              totalItem.fee_trust += item.fee_trust;
              totalItem.fee_other += item.fee_other;
              totalItem.profit += item.profit;
              totalItem.salary_dsvc += item.salary_dsvc;
              totalItem.salary_dvtt += item.salary_dvtt;
              totalItem.package_profit += item.package_profit;
              if (["K", "C", "G", "H"].includes(item.package_id[0])) {
                totalItem.fee_transport_k += item.fee_transport;
              } else if (["O", "U", "E", "I"].includes(item.package_id[0])) {
                totalItem.fee_transport_o += item.fee_transport;
              }
              return totalItem;
            },
            {
              staff_name: user.name,
              commission_vc: user.commission_vc || 0,
              commission_tt: user.commission_tt || 0,
              target_vc: userMonth ? userMonth.target_vc : 0,
              target_tt: userMonth ? userMonth.target_tt : 0,
              fee_transport: 0,
              fee_transport_k: 0,
              fee_transport_o: 0,
              fee_trust: 0,
              fee_other: 0,
              profit: 0,
              salary_dsvc: 0,
              salary_dvtt: 0,
              package_profit: 0,
            }
          );
          totalItem.salary = totalItem.salary_dsvc + totalItem.salary_dvtt;
          totalItem.tpkd_fee_transport = (totalItem.fee_transport * totalItem.commission_vc) / 100;
          totalItem.tpkd_profit = (totalItem.profit * totalItem.commission_tt) / 100;
          this.items.push(totalItem);
        }
      }
      {
        const options: any = {
          extra: {
            statistics: true,
            truck_statistics: true,
          },
          filters: [],
          report: true,
        };
        const liqudiationSlipOptions: any = {
          extra: {},
          filters: [],
          report: true,
        };

        if (this.currentUser.id) {
          options.auth_override = {
            id: this.currentUser.id,
            role: "kd",
            codes: this.currentUser.codes || [],
            ids: [],
          };
          liqudiationSlipOptions.extra.saleUserId = this.currentUser.id;
        }
        switch (this.currentStatus) {
          case "dtl":
            options.extra.liquidation_slip_approve_time = {
              from: this.startTime,
              to: this.endTime,
            };
            liqudiationSlipOptions.filters.push({
              key: "approve_time",
              operator: ">=",
              value: this.startTime,
            });
            liqudiationSlipOptions.filters.push({
              key: "approve_time",
              operator: "<=",
              value: this.endTime,
            });
            break;
          case "ctl":
            options.extra.truck_vn_time = {
              from: this.startTime,
              to: this.endTime,
            };
            options.extra.liquidation_slip_approved = false;
            liqudiationSlipOptions.filters.push({
              key: "created_at",
              operator: ">=",
              value: moment(this.startTime * 1000).format("Y-M-D"),
            });
            liqudiationSlipOptions.filters.push({
              key: "created_at",
              operator: ">=",
              value: moment(this.endTime * 1000).format("Y-M-D"),
            });
            break;
          case "hdv":
            options.extra.truck_vn_time = {
              from: this.startTime,
              to: this.endTime,
            };
            liqudiationSlipOptions.filters.push({
              key: "approve_time",
              operator: ">=",
              value: this.startTime,
            });
            liqudiationSlipOptions.filters.push({
              key: "approve_time",
              operator: "<=",
              value: this.endTime,
            });
            break;
        }
        let { items } = await apiClient.packageGetAll(options);
        const { items: liquidationSlips } = await apiClient.liquidationSlipGetAll(liqudiationSlipOptions);

        items = items.map((item) => {
          if (item.truck) {
            item.salary_dsvc =
              ((item.truck.type.startsWith("db") && 0.08) || (item.truck.type.startsWith("nx") && 0.02) || 0.04) *
              item.fee_transport;
          }
          item.salary_dvtt = item.profit * 0.2;
          if (item.truck && item.truck_total_volume) {
            item.cost = (item.volume / item.truck_total_volume) * (item.truck?.export_fee_total || 0);
          } else {
            item.cost = 0;
          }

          item.package_profit = item.fee_transport + item.fee_trust + item.profit - item.cost;
          return item;
        });

        this.totalItem = items.reduce(
          (totalItem, item) => {
            totalItem.fee_transport += item.fee_transport;
            totalItem.fee_trust += item.fee_trust;
            totalItem.fee_other += item.fee_other;
            totalItem.profit += item.profit;
            totalItem.salary_dsvc += item.salary_dsvc;
            totalItem.salary_dvtt += item.salary_dvtt;
            totalItem.package_profit += item.package_profit;
            return totalItem;
          },
          {
            staffs_salary_vc: this.items.reduce((total, item) => {
              total += item.tpkd_fee_transport;
              return total;
            }, 0),
            staffs_salary_tt: this.items.reduce((total, item) => {
              total += item.tpkd_profit;
              return total;
            }, 0),
            fee_transport: 0,
            fee_trust: 0,
            fee_other: 0,
            profit: 0,
            salary_dsvc: 0,
            salary_dvtt: 0,
            package_profit: 0,
          }
        );
        this.totalItem.salary =
          this.totalItem.salary_dsvc +
          this.totalItem.salary_dvtt +
          this.totalItem.staffs_salary_vc +
          this.totalItem.staffs_salary_tt;

        this.totalItem.surplusage = 0;
        liquidationSlips.forEach((item) => {
          self.totalItem.surplusage += item.surplusage;
        });

        this.totalItem.target_vc = this.items.reduce((total, item) => {
          return total + item.target_vc;
        }, 0);
        this.totalItem.target_tt = this.items.reduce((total, item) => {
          return total + item.target_tt;
        }, 0);
        this.totalItem.department_fee_transport = this.items.reduce((total, item) => {
          return total + item.fee_transport;
        }, 0);
        this.totalItem.department_profit = this.items.reduce((total, item) => {
          return total + item.profit;
        }, 0);
      }

      this.loading = false;
    },
    async targetDialogSave() {
      for (const kd of this.permittedKds) {
        await apiClient.updateUserMonth(kd.id, this.month, {
          target_vc: kd.target_vc,
          target_tt: kd.target_tt,
        });
      }
      alert("Cập nhật thành công");
      this.targetDialog = false;
      this.initialize();
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
    this.startDate = moment().startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().endOf("month").format("YYYY-MM-DD");

    const userInfo = userManager.getUserInfo();
    if (userManager.checkRole(["gdkd"], true)) {
      const items = await apiClient.getTpkdByUser(userInfo.sub);
      for (const user of items) {
        this.users.push(user);
      }
      this.users.push({
        id: userInfo.sub,
        name: userInfo.name,
      });
    } else if (userManager.checkRole(["tpkd"], true)) {
      const user = {
        id: userInfo.sub,
        name: userInfo.name,
      };
      this.currentUser = user;
      this.users.push(user);
    } else {
      const { items } = await apiClient.userGetAll({
        filters: [
          {
            key: "roles",
            operator: "match",
            value: "tpkd",
          },
        ],
      });
      for (const user of items) {
        this.users.push(user);
      }
    }
    {
      const { items } = await apiClient.userGetAll({
        filters: [
          {
            key: "roles",
            operator: "match",
            value: "kd",
          },
        ],
      });
      this.kds = items;
    }
  },
});
